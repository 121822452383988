.authorization {
  /* 1 Positioning */

  /* 2 Block model */
  margin: 0 auto;
  max-width: 560px;
  width: 100%;

  /* 3 Typography */

  /* 4 Visual */

  /* 5 Animation */

  /* 6 Misc */
}
.authorization__header {
  /* 2 Block model */
  margin-bottom: 48px;
}
.authorization__header .h1 {
  /* 3 Typography */
  text-align: center;
  font-size: 48px;
  line-height: 56px;
  font-weight: 500;
  color: #292325;
}
.authorization__subtitle {
  /* 2 Block model */
  margin: 0 auto;
  padding-top: 16px;
  max-width: 496px;

  /* 3 Typography */
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  color: #292325;
}
.authorization__subtitle a {
  /* 3 Typography */
  color: #D79600;
  text-decoration: none;
}
.authorization__menu {
  /* 2 Block model */
  display: flex;
  padding: 0 22px;
  padding-bottom: 8px;
  margin: 0 auto;
  //max-width: 542px;
}
.authorization__switch-link {
  /* 1 Positioning */
  position: relative;

  /* 2 Block model */
  display: block;
  margin-right: 73px;
  margin-bottom: 16px;

  /* 3 Typography */
  // font-family: $font-main;
  font-size: 48px;
  line-height: 56px;
  font-weight: 100;
  color: #292325;
  text-decoration: none;

  outline-style: none;
  -moz-outline-style: none;

  /* 4 Visual */
  cursor: pointer;
}
.authorization__switch-link:last-child {
  /* 2 Block model */
  margin-right: 0;
}
.authorization__switch-link--active {
  /* 3 Typography */
  font-weight: 500;
}
.authorization__switch-link--active::before {
  /* 1 Positioning */
  position: absolute;
  left: 0;
  bottom: -16px;

  /* 2 Block model */
  display: block;
  width: 100%;
  height: 2px;

  /* 4 Visual */
  content: '';
  background-color: #A67E52;
}
.authorization__body {
  /* 2 Block model */
  margin: 0 auto;
  max-width: 542px;
}
.authorization__bottom {
  /* 2 Block model */
  padding-top: 16px;
  margin: 0 auto;
  max-width: 542px;

  /* 3 Typography */
  font-size: 20px;
  line-height: 32px;
  font-weight: 100;
  color: #979797;
  text-align: center;
}
.authorization__bottom a {
  /* 3 Typography */
  color: #292325;
  text-decoration: none;
}
