.balance {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  // padding: 0 80px;
	/* 1 Positioning */

	/* 2 Block model */

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.balance--body-center .balance__body {
	/* 1 Positioning */

	/* 2 Block model */

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
