.checkbox {
  /* 2 Block model */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkbox label {
  /* 1 Positioning */
  position: relative;

  /* 2 Block model */
  display: flex;
  height: 24px;
  padding-left: 0;
  padding-right: 24px;

  /* 3 Typography */
  text-align: left;

  /* 6 Sundry */
  cursor: pointer;
}
.checkbox input[type="checkbox"] {
  /* 2 Block model */
  display: none;
}
.checkbox input[type="checkbox"] + span {
  /* 1 Positioning */
  position: absolute;
  top: 0;
  left: 0;
  right: auto;

  /* 2 Block model */
  box-sizing: border-box;
  display: inline-block;
  width: 24px;
  height: 24px;

  /* 4 Visual */
  border-radius: 2px;
  border: 1px solid #C0C0C0;
}
.checkbox input[type="checkbox"] + span::before {
  /* 4 Visual */
  content: none;
}
.checkbox input[type="checkbox"]:checked + span {
  /* 4 Visual */
  // background-color: #DADFEB;
}
.checkbox input[type="checkbox"]:checked + span::before {
  /* 1 Positioning */
  position: absolute;
  top: 50%;
  left: 50%;

  /* 2 Block model */
  display: block;
  width: 10px;
  height: 8px;

  /* 4 Visual */
  content: "";
  background: none;
  // background-image: url(../images/checkbox--checked.svg);
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNMC4xMTUzODUgNC40QzAuMDM4NDYxNSA0LjMyIDAgNC4yIDAgNC4xMkMwIDQuMDQgMC4wMzg0NjE1IDMuOTIgMC4xMTUzODUgMy44NEwwLjY1Mzg0NiAzLjI4QzAuODA3NjkyIDMuMTIgMS4wMzg0NiAzLjEyIDEuMTkyMzEgMy4yOEwxLjIzMDc3IDMuMzJMMy4zNDYxNSA1LjY4QzMuNDIzMDggNS43NiAzLjUzODQ2IDUuNzYgMy42MTUzOCA1LjY4TDguNzY5MjMgMC4xMkg4LjgwNzY5QzguOTYxNTQgLTAuMDQgOS4xOTIzMSAtMC4wNCA5LjM0NjE1IDAuMTJMOS44ODQ2MSAwLjY4QzEwLjAzODUgMC44NCAxMC4wMzg1IDEuMDggOS44ODQ2MSAxLjI0TDMuNzMwNzcgNy44OEMzLjY1Mzg1IDcuOTYgMy41NzY5MiA4IDMuNDYxNTQgOEMzLjM0NjE1IDggMy4yNjkyMyA3Ljk2IDMuMTkyMzEgNy44OEwwLjE5MjMwOCA0LjUyTDAuMTE1Mzg1IDQuNFoiIGZpbGw9IiMwMDAwMDAiLz4NCjwvc3ZnPg0K');
  border-radius: 2px;
  transform: translate(-50%, -50%);

  /* 5 Animation */
  transition: all 0.3s;
}
