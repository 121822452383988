.main {
	/* 2 Block model */
	display: flex;
	flex-grow: 2;
	padding-top: 88px;
  padding-bottom: 88px;

	&--center-horizontal {
		justify-content: center;
	}
	&--center-vertical {
		align-items: center;
	}

	&--padding-aquire {
		padding: 120px 0;
	}
}
