.operations-list {
	/* 1 Positioning */

	/* 2 Block model */

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.operations-list__item {
	/* 2 Block model */
	margin-bottom: 48px;
}
.operations-list__item:last-child {
	/* 2 Block model */
	margin-bottom: 0;
}