.h1 {
	/* 2 Block model */
	margin: 0;

	/* 3 Typography */
	// font-family: $font-main;
	font-size: 40px;
	font-weight: 500;
	line-height: 56px;
	color: #292325;
}