.menu {
	/* 2 Block model */
}
.menu__list {
	/* 2 Block model */
	display: flex;
	padding: 0;
	margin: 0;

	/* 3 Typography */
	text-align: center;

	/* 4 Visual */
	list-style: none;
}
.menu__item {
	/* 1 Positioning */
	position: relative;

	/* 2 Block model */
	padding-top: 44px;
	padding-bottom: 44px;
	margin-left: 38px;
	margin-right: 39px;
	margin-bottom: 0;
}
.menu__item:first-child {
	/* 2 Block model */
	margin-left: 0;
}
.menu__item:last-child {
	/* 2 Block model */
	margin-right: 0;
}
.menu__item--active::before {
	position: absolute;
	left: 0;
	bottom: 0;

	display: block;
	width: 100%;
	height: 7px;

	content: '';
	background-color: #FFB200;
}
.menu__link {
	/* 3 Typography */
	// font-family: $font-main;
	font-size: 40px;
	font-weight: 100;
	line-height: 56px;
	color: #F2F2F2;
	text-decoration: none;
	outline-style: none;
	-moz-outline-style: none;

	/* 5 Animation */
	transition: all 0.4s;
}
.menu__link:hover {
	/* 4 Visual */
  opacity: 0.7;
  cursor: pointer;
}

/* 1 Positioning */

/* 2 Block model */

/* 3 Typography */

/* 4 Visual */

/* 5 Animation */

/* 6 Misc */
