.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2320217a;
}
.modal {
	/* 1 Positioning */
  position: relative;

  width: 550px;
  height: 440px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 56px 0;


	/* 2 Block model */

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.modal__close {
	/* 1 Positioning */
	position: absolute;
  top: 16px;
  right: 18px;

	/* 2 Block model */
	display: flex;
	justify-content: center;
	align-items: center;
	width: 56px;
	height: 56px;

	/* 4 Visual */
	background-color: #EAEAEA;
	border-radius: 50%;
	border: none;
	cursor: pointer;
}
.modal__body {
	/* 2 Block model */
	width: 383px;
	margin: 0 auto
}
.modal__title {
	/* 2 Block model */
  margin: 0px;
  margin-bottom: 48px;

	/* 3 Typography */
	text-align: center;
	font-size: 38px;
	line-height: 56px;
	font-weight: 500;
	color: #292325;
}
.modal__form {
  & .input {
    & .input__label-text {
      width: 320px;
    }
  }

  &-button {
    margin-top: 72px
  }
}
