.operations-list-month {
  cursor: pointer;
	/* 1 Positioning */

	/* 2 Block model */

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

  /* 6 Misc */

  &__title {
    /* 2 Block model */
    margin-bottom: 32px;
    padding: 0 80px;

    /* 3 Typography */
    font-size: 22px;
    line-height: 32px;
    font-weight: normal;
    color: #000000;

    text-transform: capitalize;
  }

  &__list {
    /* 2 Block model */
    padding: 0;
    margin: 0;

    /* 4 Visual */
    list-style: none;

    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__item {
      /* 2 Block model */
      margin: 0;

      /* 4 Visual */
      // border-bottom: 1px solid #E1E1E1;

      /* 2 Block model */
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 80px;

      /* 3 Typography */
      text-decoration: none;
      font-size: 22px;
      line-height: 36px;
      font-weight: normal;

      /* 4 Visual */

      /* 5 Animation */
      transition: all 0.4s;

      &--left {
        /* 2 Block model */
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &--icon {
        /* 2 Block model */
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin-right: 25px;

        ///* 4 Visual */
        //border-radius: 50%;
        //background-color: #DEE2EA;
        //
        ///* 5 Animation */
        //transition: all 0.3s;
        //
        //& img {
        //  /* 2 Block model */
        //  display: block;
        //  width: 14px;
        //  height: 24px;
        //}
        //
        //&-rotated {
        //  transform: rotateZ(180deg);
        //}
      }

      &--date {
        width: 210px;
        /* 3 Typography */
        color: #8D92A1;

        /* 5 Animation */
        transition: all 0.4s;
        text-transform: capitalize;
      }

      &--status {
        font-size: 22px;
        color: #FFB200;
        letter-spacing: 0;
        line-height: 36px;
      }

      &--right {
        /* 3 Typography */
        color: #000000;
      }

      &:hover {
        /* 4 Visual */
        background-color: #ECE9E6;

        /* 5 Animation */
        transition: all 0.4s;

        &--date {
          /* 3 Typography */
          color: #000000;

          /* 5 Animation */
          transition: all 0.4s;
        }

        &--icon {
          /* 4 Visual */
          transform: rotate(180deg);
          background-color: #F1F1F1;

          /* 5 Animation */
          transition: all 0.3s;
        }
      }
    }

    &__divider {
      height: 1px;
      background-color: #E1E1E1;
      margin: 0 80px;
      // border-bottom: 1px solid #E1E1E1;
    }
  }
}
