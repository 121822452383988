.menu-footer {
	/* 1 Positioning */

	/* 2 Block model */

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.menu-footer__list {
	/* 2 Block model */	
	display: flex;
	padding: 0;
	margin: 0;

	/* 4 Visual */
	list-style: none;
}
.menu-footer__item {
	/* 2 Block model */
	padding-left: 33px;
}
.menu-footer__link {
	/* 3 Typography */
	font-size: 16px;
	line-height: 24px;
	text-decoration: none;
	color: #232021;
}
.menu-footer__link:hover {
	/* 4 Visual */
	opacity: 0.7;
}