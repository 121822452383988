.header-authorization {
  /* 1 Positioning */

  /* 2 Block model */
  padding-top: 36px;

  /* 3 Typography */

  /* 4 Visual */

  /* 5 Animation */

  /* 6 Misc */
}
.header-authorization {
  /* 2 Block model */
  &__menu {
    padding-top: 15px;

    &__list {
      /* 2 Block model */
      display: flex;
      padding: 0;
      margin: 0;

      /* 3 Typography */
      text-align: center;

      /* 4 Visual */
      list-style: none;
    }
    &__item {
      /* 1 Positioning */
      position: relative;

      /* 2 Block model */
      margin-left: 28px;
      margin-right: 28px;

      &:first-child {
        /* 2 Block model */
        margin-left: 0;
      }
      &:last-child {
        /* 2 Block model */
        margin-right: 0;
      }
    }
    &__link {
      /* 3 Typography */
      // font-family: $font-main;
      font-size: 18px;
      font-weight: bold;
      line-height: 21px;
      color: #232021;
      text-decoration: none;

      /* 5 Animation */
      transition: all 0.4s;

      &:hover {
        /* 4 Visual */
        opacity: 0.7;
      }
    }
  }
}
