.btn {
	/* 1 Positioning */
	position: relative;

	/* 2 Block model */
	box-sizing: border-box;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 4px 36px;
	margin: 0;

	/* 3 Typography */
	// font-family: $font-main;
	font-size: 24px;
	font-weight: 500;
	line-height: 48px;
	color: #000000;
	text-decoration: none;
	vertical-align: middle;

	/* 4 Visual */
	cursor: pointer;
	user-select: none;
	background-color: #FFB200;
	border: 1px solid #FFB200;
	outline: none;
	border-radius: 10px;

	/* 5 Animation */
	transition: all 0.4s;
	-moz-appearance: none;
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
}
.btn:hover {
	/* 4 Visual */
	opacity: 0.7;
}
.btn:focus {
	/* 4 Visual */
	opacity: 0.7;
}
.btn .icon {
	/* 2 Block model */
	box-sizing: inherit;
	flex-shrink: 0;
	width: 15px;
	height: 15px;

	/* 4 Visual */
	fill: #ffffff;

	/* 5 Animation */
	transition: all 0.4s;
}
.btn:hover .icon {
	/* 4 Visual */
	fill: #000000;
}
.btn .icon:first-child:not(:last-child) {
	/* 2 Block model */
	margin-right: 15px;
}
.btn .icon:last-child:not(:first-child) {
	/* 2 Block model */
	margin-left: 15px;
}
.btn:disabled,
.btn[disabled],
.btn:disabled:hover,
.btn[disabled]:hover {
	/* 3 Typography */
	color: #909090;

	/* 4 Visual */
	background-color: #F3DAA1;
	border: 1px solid #F3DAA1;
	cursor: default; 
	opacity: 1;
}

.btn--black {
	/* 3 Typography */
	color: #F1F1F1;

	/* 4 Visual */
	background-color: #232021;	
	border: 1px solid #232021;	
}

/* -------------------------------------------- */

/*
 * Modifiers
 *
 */

 @import '--block/--block.scss';
 @import '--sm/--sm.scss';
 @import '--lg/--lg.scss';