.push {
  /* 2 Block model */
  position: fixed;
  bottom: 80px;
  right: 80px;
  display: none;
  opacity: 0;
	justify-content: space-between;
	align-items: flex-start;
	margin: 0;
  padding: 14px 20px 14px 17px;
  z-index: 9999;

	/* 3 Typography */
	font-size: 20px;
	font-weight: normal;
	line-height: 28px;
	color: #ffffff;

	background-color: #DE5D22;
  border-radius: 12px;

  &--visible {
    display: flex;
    opacity: 1;
  }
}
.push__body {
	/* 2 Block model */
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.push__icon {
	/* 2 Block model */
	display: block;
	width: 25px;
	height: 28px;
	margin-right: 15px;
}
.push button {
	padding: 0;
	margin-left: 15px;
	margin-top: 6px;

	border: none;
	background: none;
	cursor: pointer;
}
.push button img {
	/* 2 Block model */
	display: block;
	width: 15px;
	height: 15px;
}
