.form-item {
  /* 1 Positioning */
  position: relative;

  /* 2 Block model */

  /* 3 Typography */

  /* 4 Visual */

  /* 5 Animation */

  /* 6 Misc */
}
.form-item__title {
  display: flex;
  justify-content: space-between;
}
.form-item__title-value--first {
  /* 2 Block model */
  margin-bottom: 8px;
  padding-left: 24px;

  /* 3 Typography */
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #292325;
}
.form-item__bottom {
  /* 2 Block model */
  margin-top: 8px;
  padding-left: 24px;

  /* 3 Typography */
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #979797;
}

.form-item__bottom_error {
  /* 2 Block model */
  margin-top: 8px;
  padding-left: 24px;

  /* 3 Typography */
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #DE5D22;
}

.form-item__bottom_verified {
  /* 2 Block model */
  margin-top: 8px;
  padding-left: 24px;

  /* 3 Typography */
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #28a745;
}

.form-item .prompt {
	/* 1 Positioning */
	position: absolute;
	top: 32px;
	left: 100%;

	/* 2 Block model */
	margin-left: 32px;
}

.form-item--error .input {
	/* 2 Block model */
	padding: 14px 32px;

	/* 4 Visual */
	border: 1px solid #DE5D22;
}
.form-item--error .form-item__bottom {
	/* 3 Typography */
	color: #DE5D22;
}
.form-item--success .form-item__bottom {
	/* 3 Typography */
	color: #24A47E;
}