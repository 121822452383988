.select {
	/* 1 Positioning */
	position: relative;

	/* 2 Block model */

	/* 3 Typography */

	/* 4 Visual */
	background-color: #E7E7E7;
	border-radius: 12px;

	/* 5 Animation */

	/* 6 Misc */
}
.select__header {
	/* 1 Positioning */
	position: relative;

	/* 2 Block model */
	display: flex;
	width: 100%;

	/* 4 Visual */
}
.select__item {
	/* 2 Block model */
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	padding: 0 24px;

	/* 3 Typography */
	font-size: 20px;
	line-height: 40px;
	font-weight: 500;
	color: #292325;

	/* 4 Visual */
	cursor: pointer;
}
.select__header .select__item {
	/* 2 Block model */
	padding: 20px 24px
}
.select__icon {
	/* 2 Block model */
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	margin-right: 9px;

	/* 3 Typography */
	line-height: 32px;

	/* 4 Visual */
	background-color: #F1F1F1;
	border-radius: 50%;
}
.select__list {
	/* 1 Positioning */
  position: absolute;
  z-index: 4;

	/* 2 Block model */
	display: none;
	width: 100%;
	padding: 20px 0;

	/* 4 Visual */
	background-color: #E7E7E7;
	border-top: 2px solid #DBDBDB;
}
.select__list .select__item:hover {
	/* 4 Visual */
	background-color: #D7D7D7;
}
.select--open .select__list {
	/* 2 Block model */
	display: block;
}
