.account-header {
	/* 1 Positioning */
	position: relative;

	/* 2 Block model */
	display: flex;
	justify-content: flex-start;
	align-items: center;

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.account-header__user-pic {
	/* 2 Block model */
	margin-right: 10px;
}
.account-header__user-pic img {
	/* 2 Block model */
	display: block;
	width: 18px;
	height: 18px;
}
.account-header__name {
	/* 3 Typography */
	font-size: 18px;
	line-height: 24px;
	font-weight: bold;
	color: #ffffff;
}
.account-header__menu {
	/* 1 Positioning */
	position: absolute;
	top: 24px;
	right: 0;
	z-index: 2;

	/* 2 Block model */
	display: none;
	padding-top: 24px;
}
.account-header:hover .account-header__menu {
	/* 2 Block model */
	display: block;
}
