.social-icon {	
	/* 1 Positioning */

	/* 2 Block model */
	display: flex;
	align-items: center;
	padding: 0;
	margin: 0;

	/* 3 Typography */

	/* 4 Visual */
	list-style: none;

	/* 5 Animation */

	/* 6 Misc */
}
.social-icon__item {
	/* 2 Block model */
	padding-left: 30px;
}