.btn--sm {
	/* 2 Block model */
	padding: 2px 20px;

	/* 3 Typography */
	font-size: 20px;
	line-height: 32px;

	/* 4 Visual */
	border-radius: 6px;
}