.input--placeholder {
  /* 1 Positioning */
  position: relative;

  /* 2 Block model */

  /* 3 Typography */

  /* 4 Visual */

  /* 5 Animation */

  /* 6 Misc */
}
.input--placeholder .input__label-text {
  /* 1 Positioning */
  position: absolute;
  top: 18px;
  left: 33px;
  width: 430px;
  background-color: #E7E7E7;
  z-index: 3;

  /* 3 Typography */
  // font-family: $font-main;
  font-size: 24px;
  font-weight: 500;
  line-height: 48px;
  color: #000000;

  /* 5 Animation */
  transition: all 0.3s;
}
.input--placeholder input {
  /* 2 Block model */

  /* 3 Typography */
  line-height: 32px;
  border: none;
}
.input--placeholder input:focus + .input__label-text {
  /* 1 Positioning */
  top: 8px;
  left: 30px;
  width: auto;

  /* 3 Typography */
  font-size: 16px;
  line-height: 20px;
  color: #818796;
}
.input--placeholder input:valid + .input__label-text {
  /* 1 Positioning */
  top: 8px;
  left: 30px;

  /* 3 Typography */
  font-size: 16px;
  line-height: 20px;
  color: #818796;
}
.input__btn-pass {
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
