.deposit {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    margin-bottom: 56px;
  }

  &__qr {
    width: 323px;
    height: 323px;
    margin-bottom: 40px;
  }

  &__info {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.33;
    margin-bottom: 24px;
  }
}
