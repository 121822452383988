.user-menu {
	/* 1 Positioning */

	/* 2 Block model */
	padding: 24px;

	/* 3 Typography */

	/* 4 Visual */
	background-color: #ffffff;
	border-radius: 8px;
	box-shadow: 0px 6px 21px rgba(0, 0, 0, 0.0589215);

	/* 5 Animation */

	/* 6 Misc */
}
.user-menu__header {
	/* 2 Block model */
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 24px;
}
.user-menu__user-pic {
	/* 2 Block model */
	margin-right: 24px;
}
.user-menu__user-pic img {
	/* 2 Block model */
	display: block;
	width: 56px;
}
.user-menu__name {
	/* 3 Typography */
	font-size: 24px;
	line-height: 28px;
	font-weight: 600;
	color: #000000;
}
.user-menu__menu {
	/* 2 Block model */
	padding: 0;
	margin: 0;

	/* 4 Visual */
	list-style: none;
}
.user-menu__item {
	/* 2 Block model */
	margin-bottom: 16px;
}
.user-menu__item:last-child {
	/* 2 Block model */
	margin-bottom: 0;
}
.user-menu__link {
	/* 3 Typography */
	font-size: 24px;
	line-height: 32px;
	font-weight: 300;
	text-decoration: none;
  color: #000000;
  cursor: pointer;
}
.user-menu__link:hover {
	/* 4 Visual */
	opacity: 0.7;
}
