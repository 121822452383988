.account-balance {
	/* 1 Positioning */

	/* 2 Block model */

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.account-balance__sum {
	font-size: 48px;
	line-height: 56px;
	font-weight: normal;
	color: #F2F2F2;
}
.account-balance__desc {	
	font-size: 12px;
	line-height: 16px;
	font-weight: 500;
	color: #9F9E9E;
	text-transform: uppercase;
}