.how-to-purchase-rubl {
	/* 1 Positioning */

	/* 2 Block model */

	/* 3 Typography */
	text-align: center;

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.how-to-purchase-rubl__header {
	/* 2 Block model */
	margin-bottom: 88px;
}
.how-to-purchase-rubl .h1 {
	/* 2 Block model */
	margin-bottom: 16px;

	/* 3 Typography */
	font-size: 48px;
}
.how-to-purchase-rubl__subtitle {
	/* 3 Typography */
	font-size: 24px;
	line-height: 32px;
	font-weight: normal;
	color: #989898;
}
.how-to-purchase-rubl__list {
	/* 2 Block model */
	display: flex;
	justify-content: center;
	align-items: flex-start;
}
.how-to-purchase-rubl__item {
	/* 2 Block model */
	padding: 0 15px;
	width: 260px;
	flex: 0 0 260px;
}