.spinner {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.534);

  display: none;
  justify-content: center;
  align-items: center;

  &--visible {
    display: flex;
  }
}

.loadingSpinner {
  border: 4px solid #FFB200;
  align-self: center;
  border-radius: 50%;
  border-top: 4px solid transparent;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
