.checkbox-text {
  /* 1 Positioning */

  /* 2 Block model */
  display: flex;
  align-items: flex-start;

  /* 3 Typography */

  /* 4 Visual */

  /* 5 Animation */

  /* 6 Misc */
}
.checkbox-text__checkbox {
  /* 2 Block model */
  padding-right: 24px;
}
.checkbox-text__text {
  /* 3 Typography */
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  color: #292325;
}
.checkbox-text__text a {
  /* 3 Typography */
  color: #D79600;
  text-decoration: none;
}
