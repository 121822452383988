body {
	/* 2 Block model */
	padding: 0;
	margin: 0;
	overflow-x: hidden;

	/* 3 Typography */
	font-family: $font-main;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	color: #000000;

	/* 4 Visual */
	background-color: #F1F1F1;
}
p,
ul {
	/* 2 Block model */
	padding: 0;
	margin: 0;
}
.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	white-space: nowrap;
	border: 0;
	clip-path: inset(100%);
}
.wrapper {
	/* 2 Block model */
	width: 100%;
	min-height: 100vh;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.container {
	/* 2 Block model */
	min-width: 1320px;
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}