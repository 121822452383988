.header {
	/* 1 Positioning */

	/* 2 Block model */

	/* 3 Typography */

	/* 4 Visual */
	background-color: #232021;

	/* 5 Animation */

	/* 6 Misc */
}
.header__top {
	/* 2 Block model */
	padding-top: 36px;
	padding-bottom: 36px;
}
.header .account-header {
	/* 2 Block model */
	margin-top: 12px;
}
.header__bottom {
	/* 2 Block model */
  padding-top: 7px;
  position: relative;

  & .back-button {
    position: absolute;
    bottom: -56px;
    left: 0;
    transform: translateY(100%);

    font-size: 19px;
    font-weight: 500;
    line-height: 1.68;
    color: #a67e52;

    &__arrow {
      margin-right: 16px;
    }
  }
}
.account-balance {
	/* 2 Block model */
	padding-top: 5px;
}
