.notice {
	/* 1 Positioning */

	/* 2 Block model */

	/* 3 Typography */
	text-align: center;

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.notice__icon {
	/* 2 Block model */
	margin-bottom: 24px;
}
.notice__icon img {
	/* 2 Block model */
	display: block;
	width: 184px;
	margin: 0 auto;
}
.notice__icon--sm {
	/* 2 Block model */
	margin-bottom: 16px;
}
.notice__icon--sm img {
	/* 2 Block model */
	width: 96px;
}
.notice__title {
	/* 2 Block model */
	margin-bottom: 16px;

	/* 3 Typography */
	text-align: center;
}
.notice__msg {
	/* 2 Block model */
	margin-bottom: 56px;

	/* 3 Typography */
	font-size: 24px;
	line-height: 32px;
	color: #000000;
	text-align: center;
}
.notice__msg--brown {
	/* 2 Block model */
	margin-bottom: 24px;

	/* 3 Typography */
	color: #A67E52;
}
.notice__action {
	/* 3 Typography */
	text-align: center;
}
.notice > div:last-child {
	/* 2 Block model */
	margin-bottom: 0;
}