.prompt {
	/* 1 Positioning */
	position: relative;

	/* 2 Block model */
	width: 100%;
	max-width: 327px;
	padding: 18px 33px 28px 30px;

	/* 3 Typography */

	/* 4 Visual */
	background-color: #ECECEC;
	border-radius: 8px;

	/* 5 Animation */

	/* 6 Misc */
}
.prompt::before {
	/* 1 Positioning */
	position: absolute;
	top: 30px;
	left: -16px;

	/* 2 Block model */
	display: block;
	width: 16px;
	height: 23px;

	/* 4 Visual */
	content: '';
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAxNiAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjEyOTc2IDEyLjMxMkMwLjU3NDYxIDExLjkxMyAwLjU3NDYwOSAxMS4wODcgMS4xMjk3NiAxMC42ODhMMTYgMFYyM0wxLjEyOTc2IDEyLjMxMloiIGZpbGw9IiNFQ0VDRUMiLz4KPC9zdmc+Cg==');
	background-size: cover;
}
.prompt__title {
	/* 2 Block model */
	margin-bottom: 18px;

	/* 3 Typography */
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	color: #292325;
}
.prompt__list {
	/* 2 Block model */
	padding: 0;
	margin: 0;

	/* 4 Visual */
	list-style: none;
}
.prompt__item {
	/* 1 Positioning */

	/* 2 Block model */
	display: flex;
	justify-content: flex-start;
	align-items: center;

	/* 3 Typography */
	font-size: 20px;
	line-height: 32px;
	font-weight: 100;
	color: #292325;

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.prompt__icon {
	/* 2 Block model */
	//padding-right: 20px;
	width: 25px;
	/* 3 Typography */
	font-weight: normal;
}