.input-select {
	/* 1 Positioning */

	/* 2 Block model */
	display: flex;
	align-items: center;

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.input-select .input {
	/* 4 Visual */
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: 2px solid #F1F1F1;
}
.input-select .select {
	/* 2 Block model */
	width: 212px;
	flex: 0 0 212px;

	/* 4 Visual */
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}