.btn--lg {
	/* 2 Block model */
	padding: 16px 60px;

	/* 3 Typography */
	font-size: 26px;
	line-height: 48px;

	/* 4 Visual */
	border-radius: 12px;
}