@import './assets/sass/style.scss';

html, body{
  padding: 0;
  margin: 0;
}
html{
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & > div#root {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  padding: 0;
  border: none;
  &:active,
  &:focus {
    outline: none;
  }
}

div [type='button'] {
  outline: none;
}

/* FIXING AUTOFILL TEXT COLOR */
/* MOVE WHERE NEEDED */
input:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:hover {
  border: none;
  -webkit-text-fill-color: #292325;
  -webkit-box-shadow: 0 0 0px 1000px #e7e7e700 inset;
  transition: background-color 5000s ease-in-out 0s;
}

//  {
//   border: 1px solid #e7e7e700;
//   -webkit-text-fill-color: #292325;
//   -webkit-box-shadow: 0 0 0px 1000px #e7e7e700 inset;
//   transition: background-color 5000s ease-in-out 0s;
// }
