.qr-token {
  /* 1 Positioning */

  /* 2 Block model */
  padding: 48px 24px;

  /* 3 Typography */
  text-align: center;

  /* 4 Visual */
  border-radius: 22px;
  background-color: #D8D8D8;

  /* 5 Animation */

  /* 6 Misc */
}
.qr-token__title {
  /* 2 Block model */
  margin: 0;
  margin-bottom: 48px;

  /* 3 Typography */
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #000000;
}
.qr-token__img {
  display: block;
  width: 100%;
  max-width: 210px;
  margin: 0 auto;
  margin-bottom: 48px;
}
.qr-token__key {
  /* 2 Block model */
  margin-bottom: 32px;

  /* 3 Typography */
  font-size: 24px;
  line-height: 32px;
  font-weight: 100;
  color: #000000;

  display: flex;
  flex-direction: column;
}
.qr-token__key b {
  /* 3 Typography */
  font-size: 22px;
  font-weight: 500;
}
.qr-token__desc {
  /* 3 Typography */
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  color: #292325;
}
