.input-text {
	/* 1 Positioning */

	/* 2 Block model */
	display: flex;
	align-items: center;

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.input-text .input {
	/* 4 Visual */
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: 2px solid #F1F1F1;
}
.input-text__text {
	/* 2 Block model */
	width: 207px;
	flex: 0 1 207px;
	padding: 16px 24px 16px 0;

	/* 3 Typography */
	text-align: right;
	font-size: 26px;
	line-height: 48px;
	font-weight: normal;
	color: #000000;

	/* 4 Visual */
	background-color: #E7E7E7;
	border-radius: 0 12px 12px 0;
}
