.intensity-tabs {
	/* 1 Positioning */

	/* 2 Block model */
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 15px;

	/* 3 Typography */

	/* 4 Visual */
	border-bottom: 1px solid #D7D7D7;

	/* 5 Animation */

	/* 6 Misc */
}
.intensity-tabs__tabs {
	/* 2 Block model */
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	/* 4 Visual */
	list-style: none;
}
.intensity-tabs__item {
	/* 1 Positioning */
	position: relative;

	/* 2 Block model */
	margin-right: 25px;

	/* 3 Typography */
	font-size: 16px;
	line-height: 24px;
	font-weight: normal;
  color: #B3B9C8;

  /* 4 Visual */
  cursor: pointer;
}
.intensity-tabs__item:last-child {
	/* 2 Block model */
	margin-right: 0;
}
.intensity-tabs__item--active {
	/* 3 Typography */
	font-weight: 500;
	color: #000000;
}
.intensity-tabs__item--active::before {
	/* 1 Positioning */
	position: absolute;
	left: 0;
	right: 0;
	bottom: -15px;

	/* 2 Block model */
	display: block;
	width: 100%;
	height: 3px;

	/* 4 Visual */
	content: '';
	background-color: #FFB200;
}
.intensity-tabs__total {
	/* 3 Typography */
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	color: #292325;
}
