.redeem-form {
	/* 1 Positioning */

	/* 2 Block model */

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.redeem-form__title {
	/* 2 Block model */
	margin-bottom: 56px;

	/* 3 Typography */
	text-align: center;
}
.redeem-form__body {
	/* 2 Block model */
	width: 474px;
	margin: 0 auto;
}