.form {
	/* 1 Positioning */

	/* 2 Block model */

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.form__block,
.submit-form__block {
	/* 2 Block model */
	margin-bottom: 40px;
}
.form__block:last-child {
	/* 2 Block model */
	margin-bottom: 0;
}

.recapcha-form {
  position: relative;
  z-index: 2;

  & input {
    position: absolute;
    top: 23px;
    height: 30px;
    width: 30px;
    left: 12px;
    // background-color: red;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: rgba(0, 0, 0, 0);
    outline: none;
  }
}

.redeem-form-input {
  &.input--desc input {
    width: 75%;
  }
}

.withdraw-form-input {
  &.input--desc input {
    width: 75%;
  }
}

.acquire-form-input {
  &.input--desc input {
    width: 75%;
  }
}
