.powered-by-gp7 {
  /* 2 Block model */
  display: flex;
  align-items: center;
  padding: 10px 22px 9px 12px;

  /* 3 Typography */
  font-size: 13px;
  line-height: 31px;
  color: #ffffff;
  text-decoration: none;

  /* 4 Visual */
  background-color: #171720;
  border-radius: 6px;
}
.powered-by-gp7 img {
  /* 2 Block model */
  display: block;
  width: 28px;
  margin-right: 19px;
}
