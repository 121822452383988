.footer {
	/* 1 Positioning */

	/* 2 Block model */

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.footer__top {
	/* 2 Block model */
	padding-top: 22px;
	padding-bottom: 40px;

	/* 4 Visual */
	border-bottom: 1px solid #E3E3E3;
}
.footer__logos {
	/* 2 Block model */
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.footer__logos .logo {
	/* 2 Block model */
	margin-right: 41px;
}
.footer .menu-footer {
	/* 2 Block model */
	margin-bottom: 20px;
}
.footer__bottom {
	/* 2 Block model */
	padding-top: 20px;
	padding-bottom: 20px;
}
.footer__copy {
	font-size: 18px;
	line-height: 32px;
	color: #BBBABA;
}