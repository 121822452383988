.crypto-address {
	/* 2 Block model */
	display: flex;
	align-items: center;

	/* 4 Visual */
	border-radius: 12px;
  background-color: #E7E7E7;
  padding: 16px 21px;

  & input {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    width: 425px;
    font-family: Helvetica;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 24px;
    outline: none;
  }
}
.crypto-address__btn {
	/* 1 Positioning */

	/* 2 Block model */
	display: flex;
	justify-content: center;
	align-items: center;

	/* 3 Typography */

	/* 4 Visual */
	border: none;
	background: none;
	outline: none;
	cursor: pointer;

	/* 5 Animation */

	/* 6 Misc */
}
