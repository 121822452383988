.input {
  /* 2 Block model */
  display: flex;
  padding: 15px 33px;
  width: 100%;

  /* 4 Visual */
  background-color: #E7E7E7;
  border-radius: 12px;
}
.input input {
  /* 1 Positioning */
  position: relative;
  z-index: 2;

  /* 2 Block model */
  //margin-top: 0px;
  display: block;
  width: 100%;

  /* 3 Typography */
  // font-family: $font-main;
  font-size: 24px;
  font-weight: 500;
  line-height: 48px;
  color: #000000;

  /* 4 Visual */
  border: none;
  background: none;
  outline: none;
  text-overflow: ellipsis;
}
.input input::-webkit-input-placeholder {
  /* 3 Typography */
  color: #292325;
}
.input input::-moz-placeholder {
  /* 3 Typography */
  color: #292325;
  text-overflow: ellipsis;

  /* 4 Visual */
  opacity: 1;
}
.input input:-moz-placeholder {
  /* 3 Typography */
  color: #292325;
  text-overflow: ellipsis;

  /* 4 Visual */
  opacity: 1;
}
.input input:-ms-input-placeholder {
  /* 3 Typography */
  color: #292325;
  text-overflow: ellipsis;
}
.input input[placeholder] {
  /* 3 Typography */
  text-overflow: ellipsis;
}
.input input:hover {
  /* 4 Visual */
  cursor: pointer;
  opacity: 0.7;
}

.input input[type=number]::-webkit-outer-spin-button,
.input input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input input[type=number] {
  -moz-appearance:textfield;
}

.input-address input {
  font-size: 16px;
}


@import './input-desc.scss';
