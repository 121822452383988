.input--desc {
	/* 1 Positioning */

	/* 2 Block model */
	justify-content: flex-start;
	align-items: center;

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.input--desc input {
  /* 2 Block model */
  /* TODO: make dynamic */
  width: 170px;
  /* TODO: ПЕРЕДЕЛАТЬ ЛОГИКУ ИНПУТОВ!!! СЛИШКОМ СВЯЗАНА И ОДИН ЛОМАЕТ ДРУГОЙ */

  /* 3 Typography */
  /* TODO: uncomment */
	/* text-align: right; */
}
.input__desc {
	/* 3 Typography */
	font-size: 24px;
	line-height: 32px;
	font-weight: 500;
	color: #0c0c0c;
}
