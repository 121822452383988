.transaction-details {
	/* 1 Positioning */

	/* 2 Block model */
	display: flex;
	flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
	align-items: center;
  height: 100%;
  padding: 0 80px;

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.transaction-details__body {
	/* 2 Block model */
	padding: 40px 0;

	/* 3 Typography */
	text-align: center;
}
.transaction-details__subtitle {
	/* 2 Block model */
	margin: 0;
	margin-bottom: 37px;

	/* 3 Typography */
	font-size: 48px;
	line-height: 56px;
	font-weight: 500;
	color: #292325;
	text-align: center;
}
.transaction-details__sum {
	/* 2 Block model */
	margin: 0 auto;
	margin-bottom: 37px;
	display: flex;
	justify-content: center;

	/* 3 Typography */
	font-size: 64px;
	line-height: 56px;
	font-weight: normal;
	color: #232021;
}
.transaction-details__sum > div:last-child {
	/* 2 Block model */
	padding-left: 10px;

	/* 3 Typography */
	color: #B1B5C1;
}
.transaction-details__id {
	/* 2 Block model */
	margin: 0 auto;
	margin-bottom: 56px;
	padding: 2px 12px;
	display: inline-block;

	/* 3 Typography */
	font-size: 20px;
	line-height: 32px;
	font-weight: 500;
	color: #232021;

	/* 4 Visual */
	border-radius: 6px;
	background-color: #E5E5E8;
}
.transaction-details__bottom {
	/* 2 Block model */
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.transaction-details__item {
	/* 3 Typography */
	font-size: 22px;
	line-height: 36px;
	font-weight: normal;
	color: #000000;
}
.transaction-details__item div:first-child {
	/* 3 Typography */
	font-size: 16px;
	line-height: 24px;
	color: #B3B9C8;
}
