.balance-header {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
	/* 1 Positioning */

	/* 2 Block model */
  margin-bottom: 88px;
  padding: 0 80px;

	/* 3 Typography */

	/* 4 Visual */

	/* 5 Animation */

	/* 6 Misc */
}
.balance-header__top {
	/* 2 Block model */
	margin-bottom: 8px;
}

.balance-header__top_title {
	font-size: 30px;
	color: #8D92A1;
}

.balance-header__body {
	/* 2 Block model */
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.balance-header__left {
	/* 2 Block model */
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
}
.balance-header__logo {
	/* 2 Block model */
	display: block;
	width: 74px;
	margin-right: 16px;
}
.balance-header__sum {
	/* 2 Block model */
	margin-right: 14px;

	/* 3 Typography */
	font-size: 64px;
	line-height: 56px;
	font-weight: normal;
	color: #232021;
}
.balance-header__sum-sm {
	/* 3 Typography */
	font-size: 30px;
	line-height: 32px;
	font-weight: normal;
	color: #8D92A1;
}
.balance-header__right .btn {
	/* 2 Block model */
	margin-left: 24px;
}
